import api from '@/api';

export default {
  data() {
    return {
      remote: api.user,
      model: {
        name: '',
        phone: '',
        departmentId: ''
      },
      rules: {
        name: [{ required: true, message: '姓名是必填的' }],
        phone: [{ required: true, message: '手机号是必填的' }],
        department: [{ required: true, message: '部门是必选的' }]
      },
      descriptors: [
        {
          label: '姓名',
          prop: 'name',
          type: 'text'
        },
        {
          label: '手机号',
          prop: 'phone',
          type: 'text'
        },
        {
          label: '部门',
          prop: 'departmentId',
          type: 'cascader',
          action: api.department.list,
          filterable: true,
          clearable: true,
          options: [],
          props: {
            expandTrigger: 'hover',
            label: 'name',
            value: 'id',
            children: 'child',
            emitPath: false
          }
        }
      ]
    };
  }
};
